import React from "react"
import Cloud from "../images/cloud.png"
import Footer from "../components/footer"
import Layout from "../components/layout"
import ImageContainer from "../components/imageContainer"

const Style = {
  MainDiv: "gradientHome relative flex pt-24 sm:pt-40 lg:pt-48",
  TitleSection:
    "container text-center text-white mb-32 lg:mb-64",
  Title: "plus-bold text-4xl sm:text-5xl lg:text-6xl",
  Subtitle: "frk-regular text-xl sm:text-2xl",
  ListCont: "container mt-16 mb-40 lg:mb-56 text-primary text-lg frk-regular bg-white",
  ListText: "mb-4",
}

const PrivacyPolicy = () => {
  return (
    <Layout title="Privacy Policy" link="/privacy-policy">
      <section className={Style.MainDiv}>
        <ImageContainer
          container="w-full absolute self-end"
          src={Cloud}
          alt="cloud"
        />
        <div className={Style.TitleSection}>
          <h2 className={Style.Title}>Privacy Policy</h2>
          <p className={Style.Subtitle}>Last modification: May 22, 2019</p>
        </div>
      </section>
      <ul className={Style.ListCont}>
        <li className={Style.ListText}>
          1. This Privacy Policy applies to all HelloBuild LLC (Build) apps
          released on both Apple Store (for iPhone, iPad) and Google Store (for
          all Android devices).
        </li>
        <li className={Style.ListText}>
          2. Build collects information about the users of its apps, but never
          intends to collect Personal Identifiable Information. Build aims to
          collect the minimally required information for the functionality of
          each app. This is usually restricted to user-provided information for
          login purpose (name and email), plus the analytics collected and
          provided by the Apple and Google app stores.
        </li>
        <li className={Style.ListText}>
          3. Build, under its current ownership and management, commits to not
          sell any user data to third parties.
        </li>
        <li className={Style.ListText}>
          4. Users will always have the right to delete their account within an
          app, at anytime. Build will make reasonable efforts to remove all data
          associated with an account that is deleted, ASAP. Once an account is
          deleted and the data removed, it is not possible to recover that
          information.
        </li>
        <li className={Style.ListText}>
          5. Build might keep non-user provided data (analytics data) about app
          usage, even after an account is deleted. Examples of this data are:
          number of downloads of an app, number of deletes of an app, etc.
        </li>
        <li className={Style.ListText}>
          6. Build is not responsible for any data collected directly by Apple
          or by Google. In the event that such data is made available to Build,
          it will be bounded by this policy.
        </li>
        <li className={Style.ListText}>
          7. Build uses standard and current encrypting techniques to mask the
          user’s data collected. However, this might not prevent a hack, or
          guarantee that user data will not be made public by bad actors. Users
          of all Build apps accept this risk.
        </li>
        <li className={Style.ListText}>
          8. Build reserves the right to modify this Privacy Policy at anytime.
          Build will make all reasonable efforts to inform the users when
          changes are made to this policy.
        </li>
        <li className={Style.ListText}>
          9. In the event of Build being acquired (change of ownership), the new
          owners will inherit and take responsibility for all the user’s data
          collected. A new owner might also make changes to this Privacy Policy.
          Build will make all reasonable efforts to inform the users before any
          acquisition is finalized.
        </li>
        <li className={Style.ListText}>
          10. The Privacy Policy is in effect when and in the format posted
          publicly on the Build website.
        </li>
      </ul>
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy
